import React from 'react';
import { Helmet } from 'react-helmet';

import { graphql } from 'gatsby';

import DefaultLayout from '../layouts';
import ContentSection from '../layouts/content-section';
import NewsList from '../components/news-list';
// Import PostSummary query fragment from NewsItem
// eslint-disable-next-line
import NewsItem from '../components/news-item';
import Metadata from '../components/metadata';

import logo_tek_slogan from '../assets/common/ads/tek-2024-slogan.png';

const Home = ({ data }) => {
  const newsData = data.allWpPost.edges.map(({ node }) => node);

  return (
    <DefaultLayout isHomepage={true}>
      <Helmet>
        <meta
          name="google-site-verification"
          content="5RnNVGWR62H3osrHIkjaSXvtDa0rV-j2v1PuV0BpKIA"
        />
        <meta
          name="google-site-verification"
          content="qTtjQb5R_6J6SYa39bN-OtkgzxoUzhllZIGU2L-Gb70"
        />
      </Helmet>

      <Metadata />

      <ContentSection>
        <NewsList items={newsData} showMoreLink={true} />
      </ContentSection>

      <ContentSection dark={true}>
        <h1>Mikä on speksi?</h1>

        <p>
          Speksi on interaktiivisen opiskelijateatterin muoto, jolle on
          tyypillistä runsaat musiikki- ja tanssinumerot sekä ajankohtaisiin
          asioihin ja tunnettuihin tarinoihin pohjautuva opiskelijahuumori. Ihan
          tavallisesta parodiasta ei kuitenkaan ole kyse, sillä speksistä
          speksin tekee ”Omstart!”: yleisö voi koska tahansa keskeyttää show’n
          ja pyytää näyttelijöitä esittämään edelliset repliikit uudelleen ja
          ehkä uudella tavalla. Varsinkin nykyään nokkelien lisämääreiden, kuten
          ”takaperin” tai ”tammpereen murteella” liittäminen osaksi
          ”Omstart!”-huutoa lisää hauskuutta pakottamalla näyttelijän ja usein
          myös lavan taustajoukot improvisoimaan jotain ennenkuulumatonta.
          Lopputuloksena on spektaakkelinomainen huumoripaketti, jossa joka
          esitys on ainutlaatuinen.
        </p>

        <p>
          Mutta miksi speksin taikasana ”Omstart!” on ruotsia? Wikipedia tietää
          kertoa, että speksi teatterimuotona juontaa juurensa 1500-luvun
          Uppsalaan, jossa ylioppilaat opettelivat latinaa interaktiivisen
          näytelmän avulla. Sen jälkeen satojen vuosien aikana speksiperinne on
          hioutunut hyvin tiiviiksi ja vakiintuneeksi osaksi ruotsalaista
          opiskelijakulttuuria.
        </p>

        <p>
          Juuri opiskelijat – teekkarit – toivat speksin lopulta myös Suomeen
          1930-luvulla, ja niihin aikoihin nähtiin myös ensimmäiset suomalaiset
          speksit. Speksikulttuuri kuitenkin hiipui, mutta koki renessanssin
          1990-luvun alkupuolella. Siitä lähtien yhä useampi opiskelijajärjestö
          on löytänyt speksistä väylän hauskanpidolle, harrastamiselle ja
          taiteelliselle kannanotolle. Eri kaupunkien korkeakouluissa ympäri
          Suomen toimii nykyään kymmeniä speksiproduktioita, joista suurin osa
          tekee näytelmän joka vuosi.
        </p>
      </ContentSection>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <p>Yhteistyössä:</p>
      <a href={'https://www.tek.fi'} target="_blank" rel="noopener noreferrer">
        <div style={{ maxWidth: '30em' }} >
          <img src={logo_tek_slogan} alt={'TEK'} title={'TEK'} style={{ maxWidth: '100%' }} />
        </div>
      </a>
    </div>

    </DefaultLayout>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    allWpPost(limit: 5, sort: { fields: date, order: DESC }) {
      edges {
        node {
          ...PostSummary
        }
      }
    }
  }
`;
